<template>
  <v-container fluid class="main-layout">
    <v-row no-gutters>
      <v-col lg="6" v-show="$vuetify.breakpoint.mdAndUp">
        <registration-cover />
      </v-col>

      <v-col col="6" class="form-col">
        <div class="form-col-content">
          <div class="form-col2-container">
            <form novalidate @submit.prevent="save" class="top-section">
              <div class="title">Welcome!</div>
              <div class="subtitle">
                Create Account here:
              </div>
              <div class="center">
                <div class="md-layout md-gutter md-alignment-center">
                  <div class="md-layout-item item-size-100">
                    <md-field
                      :class="getValidationClass('firstName')"
                      class="field-margin"
                      id="first-name"
                    >
                      <div class="input">
                        <input
                          class="input-width"
                          name="first-name"
                          v-focus
                          placeholder="First Name"
                          v-model="model.firstName"
                          :disabled="saving"
                        />
                      </div>
                      <span class="md-error" v-if="firstNameValidationError">{{
                        firstNameValidationError
                      }}</span>
                    </md-field>
                  </div>

                  <div class="md-layout-item item-size-100">
                    <md-field
                      :class="getValidationClass('lastName')"
                      class="field-margin last_name"
                      id="last-name"
                    >
                      <div class="input">
                        <input
                          placeholder="Last Name"
                          class="input-width"
                          name="last-name"
                          v-model="model.lastName"
                          :disabled="saving"
                        />
                      </div>
                      <span class="md-error" v-if="lastNameValidationError">{{
                        lastNameValidationError
                      }}</span>
                    </md-field>
                  </div>
                </div>

                <div class="md-layout email-field-width">
                  <div class="md-layout-item item-size-100">
                    <md-field
                      :class="getValidationClass('email')"
                      class="email-field"
                      id="email"
                    >
                      <div class="email-input">
                        <input
                          placeholder="Email"
                          class="input-width"
                          type="email"
                          name="email"
                          autocomplete="email"
                          v-model="model.email"
                          :disabled="saving"
                        />
                      </div>
                      <span class="md-error" v-if="emailValidationError">{{
                        emailValidationError
                      }}</span>
                    </md-field>
                  </div>
                </div>

                <div class="md-layout md-gutter md-alignment-center">
                  <div class="md-layout-item item-size-100">
                    <md-field
                      :class="getValidationClass('userType')"
                      class="field-margin"
                      id="user-type"
                    >
                      <md-select
                        v-model="model.userType"
                        name="user-type"
                        class="input input-width"
                        placeholder="Account Type"
                        @click="accountTypeClicked"
                      >
                        <div class="account-type-dropdown">
                          <md-option
                            v-for="role in roles"
                            :key="'role-' + role.id"
                            :value="role.id"
                            >{{ role.display_name }}</md-option
                          >
                        </div>
                      </md-select>
                      <span class="md-error" v-if="userTypeValidationError">{{
                        userTypeValidationError
                      }}</span>
                    </md-field>
                  </div>
                  <div class="md-layout-item item-size-100">
                    <md-field
                      :class="getValidationClass('brandName')"
                      class="field-margin"
                      id="brand-name"
                    >
                      <div class="input">
                        <input
                          placeholder="Business Name"
                          class="input-width"
                          name="brand-name"
                          v-model="model.brandName"
                          :disabled="saving"
                        />
                      </div>
                      <span class="md-error" v-if="brandNameValidationError">{{
                        brandNameValidationError
                      }}</span>
                    </md-field>
                  </div>
                </div>

                <div class="md-layout email-field-width">
                  <div class="md-layout-item item-size-100">
                    <md-field
                      :class="getValidationClass('password')"
                      class="email-field"
                      id="password"
                    >
                      <div class="email-input">
                        <input
                          placeholder="Password"
                          class="input-width"
                          type="password"
                          name="password"
                          @blur="toggleFocusPassword"
                          @focus="toggleFocusPassword"
                          v-model="model.password"
                          :disabled="saving"
                        />
                      </div>
                      <span class="md-error" v-if="passwordValidationError">{{
                        passwordValidationError
                      }}</span>
                    </md-field>
                  </div>
                </div>

                <div class="md-layout email-field-width">
                  <div class="md-layout-item item-size-100">
                    <md-field
                      :class="getValidationClass('confirmPassword')"
                      class="email-field"
                      id="confirm-password"
                    >
                      <div class="email-input">
                        <input
                          placeholder="Confirm Password"
                          class="input-width"
                          type="password"
                          name="confirm-password"
                          v-model="model.confirmPassword"
                          :disabled="saving"
                        />
                      </div>
                      <span
                        class="md-error"
                        v-if="confirmPasswordValidationError"
                        >{{ confirmPasswordValidationError }}</span
                      >
                    </md-field>
                  </div>
                </div>

                <div class="md-layout md-gutter">
                  <div class="md-layout-item md-small-size-100">
                    <div
                      v-if="showPasswordPolicy"
                      class="password-policy password-policy-container"
                    >
                      <v-row>
                        <v-col cols="3" class="password-policy-icon">
                          <password-lock />
                        </v-col>
                        <v-col>
                          <p class="password-policy-title">
                            <strong>Your password needs to:</strong>
                          </p>
                          <ul class="password-policy__items">
                            <li class="policy-item">
                              Include at least one number.
                            </li>
                            <li class="policy-item">
                              Include both lower and upper case characters.
                            </li>
                            <li class="policy-item">
                              Be at least 6 characters long.
                            </li>
                          </ul>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </div>

                <div class="terms-style">
                  <v-checkbox
                    v-model="model.terms"
                    :error-messages="checkboxErrors"
                    required
                    @change="$v.model.terms.$touch()"
                    @blur="$v.model.terms.$touch()"
                    color="#473068"
                  >
                    <template v-slot:label>
                      <span id="checkboxLabel">
                        I accept the{{" "}}<!--
                        --><a
                          href="#"
                          @click.stop="openTerms"
                          class="login-link"
                          target="_blank">terms and conditions</a>,{{" "}}<!--
                        --><a
                          href="#"
                          @click.stop="openCodeOfConduct"
                          class="login-link" target="_blank">code of conduct</a>{{" "}}and{{" "}}<!--
                        --><a
                          href="#"
                          @click.stop="openPrivacyPolicy"
                          class="login-link" target="_blank">privacy policy</a>.
                      </span>
                    </template>
                  </v-checkbox>
                </div>
                <div v-if="addRecaptcha" class="recaptcha">
                  <md-card-actions>
                    <vue-recaptcha
                      :sitekey="
                        (conf && conf.captcha && conf.captcha.siteKey) ||
                          '6LcqJ0MhAAAAAOqsIND4poeS9JiVHZMKBteccf8V'
                      "
                      :loadRecaptchaScript="true"
                      ref="recaptcha"
                      @verify="onCaptchaVerified"
                      @expired="onExpired"
                    >
                    </vue-recaptcha>
                  </md-card-actions>
                  <span class="md-error center" v-if="recaptchaError">{{
                    recaptchaError
                  }}</span>
                </div>

                <md-button
                  type="submit"
                  class="wide-button register-button"
                  :disabled="saving"
                  id="register"
                >
                  <div class="signup-text">Sign Up</div>
                </md-button>

                <md-button
                  v-if="ssoEnabled"
                  class="margin-s wide-button sign-with-google"
                  @click.prevent="authProvider('google')"
                  ><img src="../../assets/google_icon.png" width="20px" /> SIGN
                  UP WITH GOOGLE
                </md-button>
                <div class="md-layout bottom-section">
                  <div class="md-layout-item md-size-100">
                    <div class="already-registered-section">
                      <div class="login">
                        Already Registered?
                        <a href="#/login" class="login-link">Log In</a>
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>
            </form>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */
import validationMixin from "../../validation/validation_mixin";
import {
  required,
  email,
  minLength,
  maxLength,
  sameAs,
} from "vuelidate/lib/validators";
import restAdapter from "../../restAdapter";
import notification from "../../notification";
import validation from "../../validation";
import auth from "@/auth";
import * as conf from "../../conf.yml";
import {
  confirmBox,
  resetLeaveAlert,
  setLeaveAlert,
} from "../../utils/confirmBox";
import VueRecaptcha from "vue-recaptcha";
import RegistrationCover from "../../components/RegistrationCover.vue";

const validatePassword = (value) => {
  if (typeof value === "undefined" || value === null || value === "") {
    return true;
  }

  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/.test(value);
};

export default {
  name: "userRegistration",
  mixins: [validationMixin],
  components: { VueRecaptcha, RegistrationCover },

  data: () => ({
    showLabel: true,
    logoImg: require(`../../assets/seamless-source-banner.png`),
    items: [
      {
        src: require(`../../assets/chanodil1.png`),
      },
      {
        src: require(`../../assets/chanodil2.png`),
      },
      {
        src: require(`../../assets/chanodil3.png`),
      },
    ],
    showPasswordPolicy: false,
    roles: [],
    model: {
      firstName: null,
      lastName: null,
      userType: null,
      brandName: null,
      email: null,
      password: null,
      confirmPassword: null,
      terms: false,
    },
    recaptcha: false,
    recaptchaError: "",
    addRecaptcha: false,
    saving: false,
    showDialog: false,
    // preview: null,
    // image: null,
    message: null,
    profileUrl: require(`../../assets/person.png`),
    ignoreEditForm: false,
  }),

  directives: {
    focus: {
      // directive definition
      inserted: function(el) {
        el.focus();
      },
    },
  },
  validations: {
    model: {
      firstName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(150),
      },
      lastName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(150),
      },
      userType: {
        required,
        userValidation: (value) => value !== 100,
      },
      brandName: {
        required,
        maxLength: maxLength(150),
      },
      email: {
        required,
        email: (val) => email(validation.emailFormatter(val)),
        maxLength: maxLength(255),
      },
      password: {
        required,
        validatePassword,
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password"),
      },
      terms: {
        checked: sameAs(() => true),
      },
    },
  },
  watch: {
    model: {
      handler: function(value) {
        this.ignoreEditForm = setLeaveAlert(this.ignoreEditForm);
      },
      deep: true,
    },
  },
  computed: {
    checkboxErrors() {
      const errors = [];
      if (!this.$v.model.terms.$dirty) return errors;
      !this.$v.model.terms.checked &&
        this.save &&
        errors.push("You must accept the terms and conditions");
      return errors;
    },
    firstNameValidationError() {
      if (!this.$v.model.firstName.required) {
        return "First name is required";
      }

      if (!this.$v.model.firstName.minlength) {
        return "First name should contain at least 3 characters";
      }

      if (!this.$v.model.firstName.maxlength) {
        return "First name should less than 150 characters";
      }

      return null;
    },
    lastNameValidationError() {
      if (!this.$v.model.lastName.required) {
        return "Last name is required";
      }

      if (!this.$v.model.lastName.minlength) {
        return "Last name should contain at least 3 characters";
      }

      if (!this.$v.model.lastName.maxlength) {
        return "Last name should less than 150 characters";
      }

      return null;
    },
    userTypeValidationError() {
      if (!this.$v.model.userType.required) {
        return "The user type is required";
      }

      if (!this.$v.model.userType.userValidation) {
        return "The user type is required";
      }

      return null;
    },
    brandNameValidationError() {
      if (!this.$v.model.brandName.required) {
        return "Business or brand name is required";
      }

      if (!this.$v.model.brandName.maxlength) {
        return "Business name should less than 150 characters";
      }

      return null;
    },
    emailValidationError() {
      if (!this.$v.model.email.required) {
        return "The email is required";
      }

      if (!this.$v.model.email.email) {
        return "Invalid email";
      }

      if (!this.$v.model.email.maxlength) {
        return "Email should less than 255 characters";
      }

      return null;
    },
    passwordValidationError() {
      if (!this.$v.model.password.required) {
        return "The password is required";
      }

      if (!this.$v.model.password.validatePassword) {
        return "Please add a strong password";
      }

      return null;
    },
    confirmPasswordValidationError() {
      if (!this.$v.model.confirmPassword.required) {
        return "The confirm password is required";
      }

      if (!this.$v.model.confirmPassword.sameAsPassword) {
        return "Password and confirm password should be identical";
      }

      return null;
    },
    termsValidationError() {
      if (!this.$v.model.terms.checked) {
        return "Please accept the terms and conditions";
      }

      return null;
    },

    ssoEnabled() {
      return conf.sso == true;
    },
  },
  mounted() {
    restAdapter.get("/api/register/display_user_role").then((response) => {
      this.roles = [...response.data.roles, ...this.roles];
    });
    this.addRecaptcha = conf.addCaptcha;
  },
  methods: {
    accountTypeClicked() {
      this.showLabel = !this.showLabel;
    },
    openTerms() { 
      window.open('https://seamlesssource.com/code-of-conduct-2', '_blank');
    },
    openPrivacyPolicy() {
      window.open('https://seamlesssource.com/privacy-policy', '_blank');
    },
    openCodeOfConduct() {
      window.open('https://seamlesssource.com/code-of-conduct', '_blank');
    },
    onCaptchaVerified() {
      this.recaptcha = true;
      this.recaptchaError = "";
    },
    onExpired() {
      this.recaptcha = false;
      this.recaptchaError = "";
    },
    clearForm() {
      this.$v.$reset();
      this.model.firstName = null;
      this.model.lastName = null;
      this.model.userType = null;
      this.model.brandName = null;
      this.model.email = null;
      this.model.password = null;
      this.model.confirmPassword = null;
      if (this.addRecaptcha) {
        this.recaptcha = false;
        this.$refs.recaptcha.reset();
      }
      this.ignoreEditForm = true;
    },

    select(id) {
      if (id === 100) {
        return true;
      }

      return false;
    },
    save() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      if (!this.recaptcha && this.addRecaptcha) {
        this.recaptchaError = "Please select the captcha";
        return;
      }

      this.saving = true;

      // if (this.$refs.imageFile.files.length && this.$refs.imageFile.files[0].size > 2 * 1024 * 1024) {
      //   notification.error("File size should be less than 2 MB");
      //   this.saving = false;
      //   return;
      // }
      //
      //  const imageFile = this.$refs.imageFile.files[0];
      const formData = new FormData();
      formData.append("first_name", this.model.firstName);
      formData.append("last_name", this.model.lastName);
      formData.append("user_type", this.model.userType);
      formData.append("brand_name", this.model.brandName);
      formData.append("email", this.model.email);
      formData.append("password", this.model.password);
      formData.append("confirm_password", this.model.confirmPassword);
      formData.append("terms_and_conditions", this.model.terms);
      //formData.append("file",this.profileUrl);

      restAdapter
        .post("/api/register", formData)
        .then((response) => {
          const notify = "Please verify your email before login!";
          notification.successWithAlert(
            "You Have Registered Successfully",
            notify
          );

          this.clearForm();
          resetLeaveAlert();
          this.$router.push({ name: "Login" });
        })
        .catch((error) => {
          this.saving = false;
          if (
            error.response &&
            error.response.status == 400 &&
            error.response.data &&
            error.response.data.error
          ) {
            notification.errors(error.response.data.error);
          } else {
            notification.error("Something went wrong, please try again later");
          }
        });
    },
    toggleFocusPassword() {
      if (!this.showPasswordPolicy) {
        this.showPasswordPolicy = true;
        return;
      }

      this.showPasswordPolicy = false;
    },
    authProvider(provider) {
      const leaveGranted = confirmBox(this.$store.state.routeLeaveAlert);
      if (!leaveGranted) {
        return;
      }
      resetLeaveAlert();
      this.$auth
        .authenticate(provider)
        .then((response) => {
          this.socialLogin(provider, response);
        })
        .catch((err) => {
          if (err.error) {
            notification.error("Something went wrong, please try again later.");
          }
        });
    },
    socialLogin(provider, response) {
      restAdapter
        .post("/api/social/" + provider, response)
        .then(async (response) => {
          auth.setAuth(response.data.success);
          await this.$router.push({ path: "/complete_profile" });
          window.location.reload();
        })
        .catch((err) => {
          notification.error("Something went wrong, please try again later");
        });
    },
  },
  beforeRouteLeave(to, from, next) {
    confirmBox(this.$store.state.routeLeaveAlert, next);
  },
};
</script>

<style lang="scss" scoped>
html {
  overflow: hidden;
  height: auto;
}

body {
  height: auto;
  overflow: auto;
}

.main-layout {
  width: 100%;
  height: 100%;
  padding: 0 0 0 0;
}

.subtitle {
  margin-top: auto;
  font-weight: 400;
  font-size: 1.16rem;
  line-height: 20px;
  color: $primaryDark;
  text-align: left;
}

.seamlesssource-title {
  font-weight: 700;
  font-size: 30px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: $primaryDark;
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
}

.form-col {
  display: flex;
  max-width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.form-col-content {
  max-height: 100vh;
  width: 100%;
  overflow-y: auto;
}

.form-col2-container {
  display: flex;
  width: auto;
  justify-content: center;
  align-items: center;
}

.md-field.md-theme-default.md-invalid .md-error {
  color: $primaryDarkest !important;
  font-size: 10px;
}

.md-field.md-theme-default::before {
  background-color: transparent;
}

.input {
  background-color: $backgroundColor;
  border-radius: 4px;
  // height: 48px;
}

.email-input {
  width: 100%;
  /* background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0.15)
  ); */
  background-color: $backgroundColor;
  border-radius: 4px;
  // height: 48px;
}

.input-width {
  width: 100%;
  height: 100%;
  border-bottom: none;
  border: none;
  padding: 12px 20px;
  color: $primaryDarkest;
  opacity: 1; /* Firefox */
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 13.9971px;
  line-height: 16px;
  letter-spacing: 2px;
}

.input-width:focus {
  outline: none;
  border: 2px solid rgba(255, 255, 255, 0.75);
  // border-radius: 8px;
  color: $primaryDarkest;
  opacity: 1; /* Firefox */
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 13.9971px;
  line-height: 16px;
  letter-spacing: 2px;
}

.input-width::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $primaryDarkest;
  opacity: 1; /* Firefox */
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 16px;
  letter-spacing: 2px;
}

.terms {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.md-field {
  display: inline-block;
}

.field-margin {
  margin-bottom: 7px;
  border-bottom: none !important;
  margin-left: auto;
  margin-right: auto;
}

.email-field {
  margin-bottom: 7px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.line {
  height: 3px;
  background: rgba(255, 255, 255, 0.24);
  width: 100% !important;
  margin-top: 10px;
}

.title {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.8px;
  margin-top: auto;
  text-align: left;
  font-weight: 400;
  font-size: 2.5rem;
  line-height: 45px;
  color: $primaryDarkest;
}

.terms-sentence {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 13.9971px;
  line-height: 16px;
  letter-spacing: 1px;
  color: #492a67;
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 10px;
  cursor: pointer;

  &:hover {
    color: $danger;
  }
}

.md-field:after {
  height: 0px !important;
}

.v-messages__message {
  color: $primaryLight !important;
  font-weight: bold;
}

#checkboxLabel {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 1rem !important;
  line-height: 13px !important;
  letter-spacing: 0.5px !important;
  color: $primaryDarkest !important;
  cursor: pointer !important;
}

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-appearance: auto;
  -webkit-text-fill-color: $primaryDarkest;
  transition: background-color 5000s ease-in-out 0s;
}

.wide-button {
  width: 100%;
  min-width: 20.188em;
  height: 40px;
  border-radius: 8px;
  margin-top: 5px;
  margin: 6px 0px;
}

.sign-with-google {
  background: $paginateText;
  box-shadow: 0px 13.5045px 25.8836px rgba(61, 77, 135, 0.1);
  border-radius: 7px;
}

.terms-style {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.terms-style::v-deep .v-messages__message {
  color: $primaryDarkest;
  padding-left: 2px;
  font-size: 10px;
  line-height: 0px;
}

.terms-style::v-deep .v-icon.v-icon {
  color: $primaryDarkest;
  margin-left: -3px;
}
.terms-style::v-deep .v-input--selection-controls__ripple {
  display: none;
}

.signup-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 13.9971px;
  letter-spacing: 2px;
}

.user-type-label {
  position: relative;
}

.user-type-label::after {
  content: "<>";
}

.user-type-label::before {
  content: "";
}

select option {
  background: rgba(0, 0, 0, 0.3);
  color: $paginateText;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

.password-policy {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  color: $primaryDarkest;
}

.password-policy__items {
  padding-left: 20px;
  margin-bottom: -10px;
}

.warning-text {
  color: $danger;
}

.item-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.less-padding {
  padding: 0 20px;
}

.transparent-background {
  background: rgba(255, 255, 255, 0.9) !important;
}

.spinner-box {
  position: absolute;
  z-index: 9999;
  top: 50%;
}
.recaptcha {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -7px;
}

.login-link {
  color: $primaryDark !important;
  a {
    color: $primaryDark !important;
  }
  // font-size: ;
}

.login-link:hover {
  text-decoration: underline;
}

.login {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: $primaryDark;
}

.already-registered-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  margin-top: 0px;
}

.bottom-section {
  width: 100%;
}

.carousel {
  height: 100vh;
  background: $primaryLight;
}

.email-field-width {
  width: 100%;
}

.register-button {
  color: $paginateText;
  background: $primaryDark;
  box-shadow: 0px 12px 14px rgba(91, 61, 135, 0.23);
  border-radius: 7px;
}

.v-messages__message {
  padding-left: 100px;
}
.md-dialog-title {
  padding: 24px 100px 0;
}
.title-color {
  width: fit-content;
  color: $primaryDark !important;
  border-bottom: 1px solid $primaryDark;
}
.paragraph-color {
  color: $primaryDark;
  max-width: 100% !important;
}
.ol-style {
  margin-left: 15px;
}
.ol-style-long {
  margin-left: 20px;
}
.md-dialog-actions {
  min-height: 75px !important;
  padding: 35px 115px 30px 24px !important;
}

.md-dialog-content {
  padding: 0 100px 24px;
  line-height: 190%;
}
.close-btn {
  width: 126px;
  height: 32px;
  border: 1px solid $primaryDarkest;
  background: $popupWindow;
  color: $primaryDarkest;
  box-shadow: 6px 29px 22px rgb(62 59 99 / 18%);
  border-radius: 3px !important;
}
.agree-btn {
  width: 126px;
  height: 32px;
  border: 1px solid $primaryDarkest;
  background: $primaryDarkest !important;
  color: $paginateText !important;
  border-radius: 3px !important;
}

.md-dialog {
  background: rgba(15, 5, 29, 0.78);
  backdrop-filter: blur(4px);
}

.link-style {
  color: $linkStyle !important;
}

.md-dialog::v-deep .md-theme-default {
  max-width: 75%;
  max-height: 75%;
  background: $popupWindow;
  border-radius: 8px;
}

.md-select {
  height: 40.8px;
  overflow: unset;
}

.md-select::v-deep .md-input {
  padding-bottom: 12px;
}

.account-type-dropdown {
  background: rgba(255, 255, 255, 0.75);
  box-shadow: 3.19039px 14.8885px 12.7616px rgba(55, 78, 138, 0.1);
  border-radius: 4px;
}

.account-type-dropdown::v-deep .md-menu-item .md-list-item-button:hover {
  background: $backgroundColor;
}

.md-select::v-deep .md-input,
.md-select::v-deep .md-textarea {
  font-weight: 500 !important;
  font-size: 13.9971px;
  // line-height: 16px;
  letter-spacing: 2px;
}

.account-type-dropdown::v-deep .md-selected .md-list-item-content,
.account-type-dropdown::v-deep .router-link-active .md-list-item-content {
  color: $infoMessage;
  background: rgba(243, 244, 248, 0.6);
}

.account-type-dropdown::v-deep .md-list-item-content {
  color: $primaryDarkest;
}

.account-type-dropdown::v-deep .md-list-item-content:hover {
  color: $infoMessage;
}

.md-select::v-deep .md-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $primaryDarkest;
  opacity: 1; /* Firefox */
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 16px;
  letter-spacing: 2px;
}

.password-policy-container {
  margin-top: 15px;
  background: rgba(71, 48, 104, 0.05);
  border: 0.5px solid #473068;
  border-radius: 3px;
}

.password-policy-title {
  text-align: left;
  margin: 15px 0px 10px -15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #473068;
}
.password-policy__items {
  margin-bottom: 15px;
}
.policy-item {
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #473068;
}
.password-policy-icon {
  justify-content: center;
  display: flex;
  align-items: center;
}
</style>
